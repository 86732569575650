.changeInfoTitle {
  font-size: 40px;
}

.changeInfo, .changePassword{
  border-radius: 5px;
  border: 0px solid lightgray;
  background-color: rgb(204, 210, 212);

  margin-top: 12px;
  padding: 9px;
  max-width: 750px;
  min-width: 300px;

  text-align: left;
}
  .changeInfo h2 {
    margin-top: 2px;
    margin-bottom: 7px;
  }

.changePassword {
}

.changeEmail {
  border: 5px solid lightgray;
  background-color: white;
  border-radius: 20px;
  margin-left: 700px;
  margin-right: 400px;
  margin-top: 80px;
  height: 30vh;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.fieldInput {
  width: 32%;
  min-width: 120px;
  text-align: left;
  float: right;
}