h2 {
    margin-top: 0px;
}

/* Subscription Items */
.UserSubA {
    padding: 7px;

    border: 1px solid black;
    background-color: white;

    margin-bottom: 9px;
    display: flex;
    align-items: center;
    align-content: space-between;
    
}
    .UserSubA p {
        display: inline-block;

        font-size: 14px;
        margin-top: 0px;
    }

.SubLeft {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.SubRight {
    display: inline-block;
    position: relative;
    text-align: right;
    margin-left: auto;
}
    .SubRight button{
        margin-left: 5px;
    }

    #approve, #extend, #renew{
        background-color: #84e38f;
    }

    #decline, #remove{
        background-color: #e67c7f;
    }

.circleE, .circleF, .circleG {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;
}
    .circleE {
        background-color: rgb(226, 171, 19);
    }
    .circleF {
        background-color: #2e9345;
    }
    .circleG {
        background-color: #c01d1d;
    }

.FieldShapeButton {
    width: 200px;
    margin-left: auto;
}

.PendingRequests {
    height: 26px;
    width: 280px;
    border-radius: 15px;
    border: 1px solid black;
    display: inline-block;

    margin-right: 5px;
    
    background-color: rgb(226, 171, 19);
    color: white;
}
    .PendingRequests p {
        margin-top: 3px;
    }

    #activeContainer{
        background-color: #367c2b;
    }

    #activeContainer:hover{
        background-color: #216431;
    }

    #expiredContainer{
        background-color: #c01d1d;
    }

    #expiredContainer:hover{
        background-color: #9e1717;
    }

    #pendingContainer:hover{
        background-color: rgb(180, 137, 16);
    }

.FilterAndSearch, .Pending {
    display: inline-block;
    width: 50%;
}
    .Pending {
        text-align: left;
    }
        .Pending p {
            text-align: center;
        }
    .FilterAndSearch {
        float: right;
        text-align: right;
        display: inline;
    }

.Requests {
    width: 900px;
}

.adminTable {
    width: 1021px;
}


/* User Field Box */
.FieldBox {
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    align-content: space-between;
}

#adminForm {
    padding-top: 60px;
}