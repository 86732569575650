.main-text {
    margin: 0px;
    font-size: 50px;
}

.Cal{
    height: 80vh;
}

.selectedDate {
    font-size: 25px;
}

.react-calendar {
    /* width: 350px;
    max-width: 100%; */
    background: white;
    border: 1px solid black;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    width: 800px;
    font-size: 18px;
    margin: 0px;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation__label {
    font-size: 40px;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__days {
    height: 450px;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
    font-size: 20px;
    width: 50px;
    height: 50px;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    width: 50px;
    height: 50px;

    display: flex;
    text-align: center;
    justify-content: center;
  }
    .react-calendar__tile abbr {
      /* margin-left: 0px; */
    }

    .react-calendar__tile button {}

  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    position:relative;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
.react-calendar__viewContainer {
    width: 100%;
    height: 500px;
    font-size: 30px;
}

.calBtn {
  display: inline-block;
  border-radius: 4px;
  background-color: #7CB9E8;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin-top: 30px;
}

.calBtn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.calBtn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.calBtn:hover span {
    padding-right: 25px;
}

.calBtn:hover span:after {
    opacity: 1;
    right: 0;
}

abbr {
  font-size: 24px;
}

.calendar-container {
  margin-bottom: 20px;
  margin-left: 2px;
}

.calHeadNote {
  margin-left: 10px;
}

.noteBox {
  display: flex;
}

.noteCircle {
  height: 13px;
  width: 13px;
  background-color: rgb(230, 210, 28);
  border-radius: 50%;
  display: inline-block;

  margin-right: 5px;
}

.spacing abbr{
  margin-left: 13px;
}

.bCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: green;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}


/* Mobile */
@media screen and (max-width: 900px) {
    #Calendar {
      margin-left: 0px;
      margin-top: 88px;
      
      min-width: 300px;
      max-width: 750px;
  }

  #DashboardContent {
    flex-wrap: wrap;
    width: auto;
  }

  .react-calendar {
    width: 100%;
    font-size: 4px;
  }

  .react-calendar__navigation__label {
    font-size: 22px;
  }

  .react-calendar__tile abbr {
    font-size: 16px;
  }

  .react-calendar__month-view__weekdays abbr{
    font-size: 13px;
  }
}