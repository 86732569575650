@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Roboto&display=swap);
@import url(https://kit.fontawesome.com/a076d05399.js);
body {
  margin: 0;
  height: 100%;
  background-color: rgb(246, 250, 250);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

.App {
  height: 100%;
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
:root {
  --amplify-primary-color: #61dafb;
  --amplify-primary-tint: #61dafb;
  --amplify-primary-shade: #61dafb;
}


.note {
    background-color: #fef68a;
    border-radius: 7px;
    padding: 1rem;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: pre-wrap;
    margin: 10px;
    width: 285px;
    text-align: left;
  }
  
  textarea:not(#messageArea){
    border: none;
    resize: none;
    background-color: #fef68a;
  }

  #messageArea{
    width: 40%;
    max-width: 300px;
    min-width: 200px;
    max-height: 100px;
    min-height: 50px;
    resize: none;
    float: right;
  }
  
  textarea:focus {
    outline: none;
  }
  
  .notes-list {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 250px);
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    border-radius: 5px;
    grid-row-start: 1;
    grid-row-end: 3;
    width: 330px;
    word-wrap: break-word;
  }
  
  .save {
    background-color: #e1e1e1;
    color: black;
    border: none;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    margin-left: 5px;
  }

  .save:hover {
      background-color: gray;
      cursor: pointer;
  }

  .note-delete-btn {
    background-color: #e1e1e1;
    color: black;
    border: none;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    margin-left: 5px;
  }

  .note-delete-btn:hover {
    background-color: gray;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 5px;
    text-align: left;
  }

  .save {
    margin-left: 220px;
    width: 100px;
  }

  .NotesHeader {
    padding-left: 10px;
  }
@media screen and (max-width: 900px) {
    .NavbarPadding {
        margin-left: 0px;
        margin-top: 88px;
    }

    #SelectWeatherStation {
        margin-bottom: 10px;
        min-width: 300px;
        max-width: 900px;
        width: 100%;
    }
}
.Main-title {
    margin: 0px;
    font-size: 48px;
    text-align: left;
    padding: 0px 0px 12px 0px;
}

.env {
    text-align: right;
    margin: 0px;
    padding: 0px;
    color: black;
    font-weight: bold;
}

span {
    color: black;
    font-weight: bold;
}

.links {
    font-size: 25px;
    margin-top: 50px;
    text-align: left;
}

.links .link {
    text-decoration: none;
    color: black;
    margin-left: 15px;
    /* color: rgb(71, 197, 71); */
}

.links .link:hover {
    color: #1a83ff;
}

.clock {
    font-size: 25px;
    color: black;
    float: left;
    text-align: left;
    margin-top: -15px;
    margin-left: 5px;
}

.idfilter {
    background-color: #367c2b;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    height: 30px;
    box-shadow: 0 5px #999;
    border: none;
}

.idfilter:hover {
    background-color: #2c6423;
}

.FieldContainer{
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 9px;
    padding-bottom: 40px;
  
    width: 450px;
    font-size: 25px;
    text-align: left;
}
    .FieldContainer label{
        line-height: 1.6;
    }
    .FieldContainer input{
        margin-top: 7px;
        height: 23px;
        float: right;
    }

.Center {
    margin-top: 4px;
    float: inline-start;
}

/* Subscription Page - Thank You Page */
.ThankYouText {
    font-size: 20px;
    text-align: left;
    padding-bottom: 25px;
}

#contactButton{
    margin-right: 10px;
}

/* Subscribe Buttons */
.SubscribeButtonBox {
    margin-top: 15px;
    padding-bottom: 40px;
}

.SubscribeButtonsA {
    float: left;
    padding: 7px 15px 7px 15px;
}
.SubscribeButtonsB {
    float: right;
    padding: 7px 45px 7px 45px;
}
.SubscribeButtonsC {
    float: left;
    padding: 7px 25px 7px 25px;
}

.SubscribeButtonsD {
    float: right;
    padding: 7px 105px 7px 105px;
}

/* Subscription Page and Admin - Info Box */
.GeneralInfoBox {
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 22px;
    padding-left: 30px;
    margin-bottom: 15px;
    width: 85%;
    max-width: 750px;
    min-width: 300px;
    text-align: left;
}
    .GeneralInfoBox h3 {
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 4px;
    }
    .GeneralInfoBox p {
        margin-bottom: 0px;
    }

.SubscriptionsInfoBox {
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 22px;
    padding-left: 30px;
    margin-bottom: 15px;
  
    width: 85%;
    max-width: 750px;
    min-width: 300px;
    text-align: left;

    vertical-align: middle;
    display: table;
}
    .SubscriptionsInfoBox p {
        margin-top: 0px; 
        vertical-align: middle; 
        display: table-cell;
        padding: 0px 0px 0px 24px;
    }
    .SubscriptionsInfoBox img {
        width: 50px;
        height: 50px;
        display: table-cell;
    }

.UserInfoBox {
    display: flex;
}
    .UserInfoBoxA h4 {
        margin-bottom: 0px;
    }
    .UserInfoBoxA p {
        margin-top: 3px;
        margin-bottom: 35px;
    }

.UserInfoBoxA {
    flex: 1 1;
}

/* Subscribe Page - Subscribe Box */
.SubBox {
    height: 50px;
    padding-bottom: 8px;
}
    .SubBox small {
        padding-bottom: 4px;

        font-size: 12px;
        text-align: right;
    }

.CancelDeviceButton {
    background-color: #e83434; /* Green */
    border: none;
    width: 22px;
    height: 22px;
    padding: 4px;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
    border-radius: 100%;
    float: right;
}

.SubBottom {
    display: flex;
}
    .SubBottom p {
        margin-top: 2px;
    }

.SubscribePrice {
    min-width: 250px;
    margin-top: 10px;
    flex: 1 1;
}

.SubscribeStation {
    margin-top: 0px;
}

/* Subscribe Page - Top Section */
.SubscribeTop {
    display: flex;
}

/* Sbuscription Item */
.SubscriptionItem, .CurrentSubscriptionItem, .ConfirmSubscriptionItem {
    width: 60%;
    max-width: 480px;
    min-width: 240px;
    padding: 7px;

    border: 1px solid black;
    background-color: white;

    margin-bottom: 9px;
}
    .SubscriptionItem p, .CurrentSubscriptionItem p, .ConfirmSubscriptionItem p {
        display: inline-block;
        position: relative;
        bottom: 5px;

        font-size: 14px;
        margin-top: 0px;
    }

.SubscriptionItem {
    display: flex;
    align-content: space-between;
    align-items: center;
}
    .SubscriptionItem p {
        bottom: 0px;
    }

.CurrentSubItem {
    display: inline-block;
    margin-top: 3px;
}
    .CurrentSubItem p {
        margin-bottom: 0px;
        display: block;
    }

.circleA {
    height: 20px;
    width: 20px;
    background-color: rgb(28, 132, 230);
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;

    margin-right: 5px;
}

.circleB, .circleC, .circleD {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;
    color: white;

}
    .circleB {
        background-color: rgb(226, 171, 19);
    }
    .circleC {
        background-color: #367c2b;
    }
    .circleD {
        background-color: #c01d1d;
    }


/* Select Weather Station Component */

#SelectWeatherStation {
    width: 802pxpx;
    margin-bottom: 10px;
}

.CaretIcon{
    width: 25px;
    height: 25px;
    padding: 4px;
    margin: 0;
    cursor: pointer;
    float: right;
}

.Click {
    width: 778px;

    background-color: #367c2b;
    color: white;

    text-align: left;
    font-size: 28px;

    padding: 12px;
    margin-left: 2px;
    border-radius: 0px;

}

/* Current Subscription */


.CurrentSubscriptionItem {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
}

/* Confirmation */
.ConfirmSubscriptionItem p{
    margin-top: 5px;
}


/* Mobile */
@media screen and (max-width: 900px) {
    .Main-title {
        font-size: 47px;
    }
}
/*
.api-Current-data tr:hover {
  background-color: #4b9e5e;
}

.api-Historic-data tr:hover {
  background-color: #4b9e5e;
}
*/

table {
  /*box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
  border-radius: 5px;
  padding: 3px;
  margin-right: 13px;
  width: 330px;
  flex: 1 1;
  margin-bottom: 5px;
}

table th {
  background: #367c2b;
  color: white;
  padding: 3px;
}

table td {
  height: 60px;
}

.field-data tr:nth-child(odd) {
  background-color: #fff;
}

.field-data tr:nth-child(even) {
  background-color: #eee;
}

/*
.field-data tr:hover {
  background-color: #4b9e5e;
}
*/

.field-data  td{
  padding: 5px;
  width: 150px;
}

.field-data th {
  font-size: 25px;
}

.filterid td{
  background-color: white;
}

#APIBLOCK
{
    display:inline;
}


#DashboardContent {
  display: flex;
  width: 1500px;
}
  #DashboardLeft {
    flex-basis: 825px;
  }
  #DisplayRight {
    flex: 1 1;
    float: left;
  }

#bloc1 {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
  max-width: 803px;
  min-width: 300px;
}

#bloc1 div {
  flex: 1 1;
  flex-basis: auto;
}

.api-Current-data, .api-Historic-data {
  padding: 0px;
  display: grid;
  margin: 1px;
  list-style: none;
  background-color: #eee;
}
  .api-Current-data li, .api-Historic-data li {
    width: 100%;

    text-align: center;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .api-Current-data li:nth-child(odd), .api-Historic-data li:nth-child(odd) {
    background-color: #fff;
  }

#bloc1 h4 {
  background: #367c2b;
  color: white;
  padding: 3px;
  margin: 0px;
}

.api-Current-Box, .api-Current-Box {
  width: 100%;
  min-width: 300px;
  max-width: 800px;
}

.api-Current-Box {
  margin-right: 2px;
}

.api-Historic-Box {
  margin-left: 2px;
}

@media screen and (max-width: 900px) {
  #bloc1 {
    flex-wrap: wrap;  
  }
}

@media screen and (min-width: 900px) { 
  #bloc1 {
    flex-basis: initial;
  }

   .api-Current-data, .api-Historic-data {
      grid-template-columns: repeat(3, minmax(130px, 1fr));
  }

  .Current-Weather-ItemA {
    grid-area: span 1/span 3;
  }

  .Current-Weather-ItemB {
    grid-area: span 1/span 2;
  }

  .Yesterday-Weather-ItemA {
    grid-area: span 1/span 3;
  } 

  .api-Current-data li:nth-child(1), .api-Current-data li:nth-child(5), .api-Current-data li:nth-child(6),
  .api-Current-data li:nth-child(7), .api-Historic-data li:nth-child(1), .api-Historic-data li:nth-child(2),
  .api-Historic-data li:nth-child(3), .api-Historic-data li:nth-child(7), .api-Historic-data li:nth-child(8),
  .api-Historic-data li:nth-child(9) {
    background-color: #fff;
  }

  .api-Current-data li:nth-child(3), .api-Current-data li:nth-child(9), .api-Historic-data li:nth-child(5) {
    background-color: #eee;
  }
}

.Historic-List, .Current-Station-List, .Yesterday-Station-List {
    padding: 0px;
    display: grid;
    margin: 1px;
    list-style: none;
}
    .Historic-List li, .Current-Station-List li, .Yesterday-Station-List li {
        width: 100%;
        text-align: center;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

.Click {
    margin-left: 0px;
}

/* Weather Stations */
.CurrentStation {
    width: 100%;
    min-width: 330px;
    max-width: 802px;
    background-color: #eee;
}

.WeatherStation {
    margin-bottom: 10px;
}

.calWeatherStation {
    margin-left: 0px;
    padding: 11px;
}

.Historic-Div {
    width: 100%;
    max-width: 803px;
    min-width: 300px;
}
    .Historic-Div p {
        padding-bottom: 10px;
    }
    .Historic-Div ul {
        background-color: #eee;
    }

.Historic-Header {
    background-color: white;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Current-Station-Header, .Yesterday-Station-Header {
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* Calendar Historic Weather Stations */

.Historic-List, .Current-Station-List, .Yesterday-Station-List {
    grid-template-columns: repeat(2, minmax(130px, 1fr));
    align-items: center;
    grid-auto-rows: 65px;
}
    
    .Historic-List li:nth-child(3), .Historic-List li:nth-child(4),
    .Historic-List li:nth-child(7), .Historic-List li:nth-child(8),
    .Historic-List li:nth-child(11), .Historic-List li:nth-child(12),
    .Historic-List li:nth-child(15), .Historic-List li:nth-child(16),
    .Current-Station-List li:nth-child(3), .Current-Station-List li:nth-child(4), 
    .Current-Station-List li:nth-child(7), .Current-Station-List li:nth-child(8),
    .Current-Station-List li:nth-child(11), .Current-Station-List li:nth-child(12),
    .Yesterday-Station-List li:nth-child(3), .Yesterday-Station-List li:nth-child(4), 
    .Yesterday-Station-List li:nth-child(7), .Yesterday-Station-List li:nth-child(8),
    .Yesterday-Station-List li:nth-child(11), .Yesterday-Station-List li:nth-child(12), 
    .Yesterday-Station-List li:nth-child(15), .Yesterday-Station-List li:nth-child(16) 
    {
        background-color: #fff;
    }

.Current-Station-Item-A {
    grid-area: span 1/span 2;
}

@media screen and (min-width: 900px) {
    .Historic-List {
        grid-template-columns: repeat(4, minmax(130px, 1fr));
    }
        .Historic-List li {
            padding: 10px 0px;
        }
        .Historic-List li:nth-child(5), .Historic-List li:nth-child(6),
        .Historic-List li:nth-child(7), .Historic-List li:nth-child(8),
        .Historic-List li:nth-child(13), .Historic-List li:nth-child(14),
        .Historic-List li:nth-child(15), .Historic-List li:nth-child(16)
        {
            background-color: #fff;
        }

        .Current-Station-List li:nth-child(3), .Current-Station-List li:nth-child(4),
        .Current-Station-List li:nth-child(11), .Current-Station-List li:nth-child(12),
        .Yesterday-Station-List li:nth-child(3), .Yesterday-Station-List li:nth-child(4), 
        .Yesterday-Station-List li:nth-child(11), .Yesterday-Station-List li:nth-child(12)
        {
            background-color: #eee;
        }

        .Historic-List li:nth-child(3), .Historic-List li:nth-child(4),
        .Historic-List li:nth-child(11), .Historic-List li:nth-child(12) {
            background-color: transparent;
            background-color: initial;
        }

    /* Current and Yesterday Weather Stations */
    .Current-Station-Header, .Yesterday-Station-Header {
        background-color: #fff;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .Current-Station-Header {
        display: flex;
    }
        .Header-Align-Right {
            text-align: right;
            flex: auto;
        }

    .Current-Station-List, .Yesterday-Station-List{
        grid-template-columns: repeat(4, minmax(130px, 1fr));
        align-items: center;
        grid-auto-rows: 60px;
    }
        .Current-Station-List li:nth-child(5), .Current-Station-List li:nth-child(6), 
        .Current-Station-List li:nth-child(7), .Current-Station-List li:nth-child(8),
        .Current-Station-List li:nth-child(13), 
        .Yesterday-Station-List li:nth-child(5), .Yesterday-Station-List li:nth-child(6), 
        .Yesterday-Station-List li:nth-child(7), .Yesterday-Station-List li:nth-child(8),
        .Yesterday-Station-List li:nth-child(13), .Yesterday-Station-List li:nth-child(14), 
        .Yesterday-Station-List li:nth-child(15), .Yesterday-Station-List li:nth-child(16) {
            background-color: #fff;
        }

    .Current-Station-Item-A {
        grid-area: span 1/span 4;
    }

    .Current-Station-Title {
        position: absolute;
        left: 737px;
    }
}


@media screen and (max-width: 900px) {
    .WeatherStation {
        width: auto;
    }

    .WeatherStation table{
        word-wrap: break-word;
        table-layout: unset;

        width: 100%;
    }

    .Click {
        width: auto;
        min-width: 300px;
        max-width: 778px;

        font-size: 18px;
    }
}
body
{
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.map {
    width: 55vw;
    height: 55vh;
    position: relative;
    left: 300px;
}
#mains {
    border: 3px;
    /*box-shadow: 3px 5px grey;*/
    border-radius: 5px;
    text-align: center;
    background-color: rgb(204, 210, 212);

    padding: 9px;
    padding-bottom: 4px;
  
    max-width: 750px;
    min-width: 300px;
    height: 200px;
    font-size: 25px;
    text-align: left;
}
    #mains input {
        margin-top: 7px;
        height: 23px;
        width: 40%;
        max-width: 300px;
        min-width: 200px;
        float: right;
    }
    
#addField {
    border-radius: 5px;
    font-family: 'Roboto Condensed', sans-serif;
    margin-bottom: 20px;
    padding-left: 5px;

}
    #addField h2 {
        margin-top: 5px;
        margin-bottom: 7px;
    }
    #addField p {
        padding: 2px;
        margin: 0px;

        font-size: 20px;
        color: rgb(224, 69, 69);
    }


#addField .formGroup {
    padding: 5px;
    
    
}

#addField .formGroup .fieldName  {
   padding-right: 5px;
   display: inline-block;
   text-align: left;
   vertical-align: top;
   max-width: 300px;
   min-width: 100px;

}


Form .addField{
    border: 3px solid black;
    background-color: #7CB9E8;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

img .addField{
    width: auto;
    height: auto;
    
} 

Button .addField{
    margin-top: 5px;
    color: #FFFFFF;
    background-color:  #69FF00;
    border-radius: 5px;
    height: 30px;
    transition: all 0.5s;
    cursor: pointer;
    /*box-shadow: 0 5px #999;*/
    border: none;
}

Button .registerButton .addField{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

Button .registerButton:after .addField{
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

Button:hover .addField{
    background-color: darkgreen;
}

Button:hover .registerButton .addField{
    padding-right: 25px;
}

Button:hover .registerButton:after .addField{
    opacity: 1;
    right: 0;
  }

Button:active .addField{
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
} 

 @media (min-width: 830px){
    #main{
        /*border: 3px solid black; */
        text-align: center;
    }

    #registerForm .formGroup .addField{
        display: inline-block;
        text-align: right;
        vertical-align: top;
    }

    #registerForm .formGroup .addField{
        display: inline-block;
        text-align: right;
        vertical-align: top;

    }

    Form .addField {
        margin-left: 550px;
        margin-right: 250px;
        margin-bottom: 50px;
    }

    img .addField{
        width: auto;
        height: auto;
    }
} 


  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: 0%;
    left: 103%;
    position: fixed;
  }
  .modal-content {
    background-color: #2F4050;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
/* NOTE TO DEVELOPERS: 
 * The navigation bar for the desktop acts as it's own spot. If you want to 
 * create a new page you're going to need to apply the .NavbarPadding class
 * to the beginning of the container you want the content to be.
 */

/* Desktop Version */

.Main-Dashboard {
    padding-left: 7px;
}

.NavbarPadding {
    margin-left: 342px;
    margin-top: 27px;
    padding: 0px 20px 0px 20px;
}

#toggle:checked ~ .Navbar {
    visibility: collapse;
}
    #toggle:checked ~ .NavbarPadding {
        visibility: collapse;
    }

.profile {
    color: white;
    text-align: center;
    font-size: 13px;
}
    .profile h2 {
        padding-bottom: 10px;
    }

/* Desktop - Navigation Bar */
.Navbar {
    justify-content: center;
    align-items: center;
    background-color: #2c292c;

    position: fixed;
    top: 0px;
    float: left;

    width: 315px;
    height: 100%;
    overflow-y: auto;
    z-index: 1;

}

.Navbar-list {
    padding-left: 7px;
    width: 300px;
    color: white;
}

.Navbar-list .list-item {
    padding-top: 7px;
    margin: 0;

    list-style-type: none;
    display: flex;
    flex-direction: row;

    /*justify-content: start;*/
    /*align-items: center;*/
    border-radius: 5px;
    height: 5vh;
}
    .Navbar-list .list-item:hover {
        cursor: pointer;
        background-color: #444244;
    }   
    .menu-links {
        color: white;
        text-decoration: none;
        font-family: 'Lato', sans-serif;
        padding-left: 10px;
    }

.Box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.list-item {
    height: 52px;
}

/* Desktop Icons */
.icon span{
    font-size: 2rem;
    color: white;
    margin-left: 5px;
    margin-right: 15px;
}

.logo {
    margin-top: 20px;
    padding: 9px;

    width: 250px; 
}

.navIconA {
    display: none;
}

@media screen and (min-width: 540px) {
    .Navbar-list .list-item {
        font-size: 20px;
    }

}

@media screen and (max-height: 1500px) {
    .Navbar-list .list-item {
        font-size: 20px;
    }
}

/* Mobile */
@media screen and (max-width: 900px) {
    .Navbar {
        width: 100%;
        height: 88px;
        overflow: auto;
        transition: max-height 0.5s;
        max-height: 88px;
        overflow-y: hidden;
    }

    .NavbarPadding {
        margin-left: 0px;
        margin-top: 88px;
    }

    .Navbar.open {
        height: 100%;
        max-height: 100%;
    }

    .logo {
        width: 150px;
        margin: 0px;
    }
    
    .Navbar-list {
        padding-left: 0px;
        width: inherit;
        margin: 0px;
        font-size: 20px;
    }
        .list-item {
            padding-left: 100px;
            font-size: 20px;
        }
    
    /* X and Hamburger menu class */
    .navIconA {
        display: inline;
        width: 25px;
    }

    .profile {
        background-color: #242224;
        position: relative;
    }
        .profile button {
            float: left;
            width: 35px;
            border: none;
            background-color: #242224;
            cursor: pointer;

            position: absolute;
            left: 39px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
        }

    .Box {
        justify-content: unset;
    }

    #Profile, #thanks {
        margin-left: 0px;
        margin-top: 88px;
    }

    #ChangePassword {
        margin-left: 0px;
        margin-top: 88px;
    }

    #Subscribe {
        margin-left: 0px;
        margin-top: 88px;
    }

    #addField {
        margin-left: 0px;
    }

    #confirm{
        margin-left: 0px;
    }

}
.main-text {
    margin: 0px;
    font-size: 50px;
}

.Cal{
    height: 80vh;
}

.selectedDate {
    font-size: 25px;
}

.react-calendar {
    /* width: 350px;
    max-width: 100%; */
    background: white;
    border: 1px solid black;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    width: 800px;
    font-size: 18px;
    margin: 0px;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation__label {
    font-size: 40px;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__days {
    height: 450px;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
    font-size: 20px;
    width: 50px;
    height: 50px;
  }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    width: 50px;
    height: 50px;

    display: flex;
    text-align: center;
    justify-content: center;
  }
    .react-calendar__tile abbr {
      /* margin-left: 0px; */
    }

    .react-calendar__tile button {}

  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #ffff76;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #006edc;
    position:relative;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #1087ff;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  
.react-calendar__viewContainer {
    width: 100%;
    height: 500px;
    font-size: 30px;
}

.calBtn {
  display: inline-block;
  border-radius: 4px;
  background-color: #7CB9E8;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  margin-top: 30px;
}

.calBtn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.calBtn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.calBtn:hover span {
    padding-right: 25px;
}

.calBtn:hover span:after {
    opacity: 1;
    right: 0;
}

abbr {
  font-size: 24px;
}

.calendar-container {
  margin-bottom: 20px;
  margin-left: 2px;
}

.calHeadNote {
  margin-left: 10px;
}

.noteBox {
  display: flex;
}

.noteCircle {
  height: 13px;
  width: 13px;
  background-color: rgb(230, 210, 28);
  border-radius: 50%;
  display: inline-block;

  margin-right: 5px;
}

.spacing abbr{
  margin-left: 13px;
}

.bCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: green;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: -1;
}


/* Mobile */
@media screen and (max-width: 900px) {
    #Calendar {
      margin-left: 0px;
      margin-top: 88px;
      
      min-width: 300px;
      max-width: 750px;
  }

  #DashboardContent {
    flex-wrap: wrap;
    width: auto;
  }

  .react-calendar {
    width: 100%;
    font-size: 4px;
  }

  .react-calendar__navigation__label {
    font-size: 22px;
  }

  .react-calendar__tile abbr {
    font-size: 16px;
  }

  .react-calendar__month-view__weekdays abbr{
    font-size: 13px;
  }
}
#main  {
  border-radius: 5px;
  margin: 0px;
}

.maintitle {
  margin: 0px;
  font-size: 50px;
  text-align: left;
}

.secondTitle {
  text-align: center;
  font-size: 40px;
}

.profileIcon {
  font-size: 250px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.group1 {
  flex: 1 1;
  height: 100vh;
  flex-basis: 50%;
  text-align: left;
  border-right: 5px solid lightgray;
}

.group2 {
  flex: 2 1;
  height: 75vh;
  flex-basis: 50%;
  border-top: 5px solid lightgray;
  border-bottom: 5px solid lightgray;
  margin-top: 10vh;
  padding-top: 50px;
  line-height: 30px;
}

.group3 {
  flex: 3 1;
  height: 75vh;
  flex-basis: 50%;
  border-top: 5px solid lightgray;
  border-bottom: 5px solid lightgray;
  margin-top: 10vh;
  padding-top: 50px;
  line-height: 30px;
}

#Profile .formGroup .fieldName {
 color: black;
 display: inline-block;
 width: 50%;
 font-size: 20px;
}

#ChangePassword .formGroup .fieldName {
  color: black;
  display: inline-block;
  width: 50%;
  font-size: 20px;
 }

@media screen and (max-width: 500px) {
  #ChangePassword .formGroup .fieldName {
    color: black;
    display: inline-block;
    width: 50%;
    font-size: 15px;
   }

   #Profile .formGroup .fieldName {
    color: black;
    display: inline-block;
    width: 50%;
    font-size: 15px;
   }

   #Profile .fieldAnswer {
    color: black;
    display: inline-block;
    width: 50%;
    font-size: 15px;
    text-align: right;
  }
}

/* Unused Code 
.profilePage{
  display: flex;
  border: 5px solid lightgray;
  background-color: white;
  border-radius: 20px;
  margin-left: 500px;
  margin-right: 200px;
  margin-top: 80px;
  height: 100vh;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  justify-content: center;
  flex-direction: row;
}
*/ 
/* Profile Desktop Version */

.ProfileBoxOne, .ProfileBoxTwo, .ProfileBoxThree {
  border: 0px solid black;
  border-radius: 5px;
  background-color: rgb(204, 210, 212);

  padding: 9px;
  margin-bottom: 15px;

  /* width: 750px; */
  max-width: 750px;
  min-width: 300px;
  text-align: left;
}
  .ProfileBoxOne h2, .ProfileBoxTwo h2, .ProfileBoxThree h2 {
    font-size: 25px;
    margin-top: 0px;
  }

.fieldAnswer {
  color: black;
  display: inline-block;
  width: 50%;
  font-size: 20px;
  text-align: right;
  overflow-wrap: break-word;
}

.fieldName, .fieldAnswer {
  padding-bottom: 7px;
}

.changeLinkA, .changeLinkB {
  text-decoration: none;
  font-size: 25px;
  color: blue;
}
  .changeLinkA:hover, .changeLinkB:hover {
    color: #1a83ff;
  }
  .changeLinkA {
    text-align: left;
  }
  .changeLinkB {
    text-align: right;
  }


.changeInfoTitle {
  font-size: 40px;
}

.changeInfo, .changePassword{
  border-radius: 5px;
  border: 0px solid lightgray;
  background-color: rgb(204, 210, 212);

  margin-top: 12px;
  padding: 9px;
  max-width: 750px;
  min-width: 300px;

  text-align: left;
}
  .changeInfo h2 {
    margin-top: 2px;
    margin-bottom: 7px;
  }

.changePassword {
}

.changeEmail {
  border: 5px solid lightgray;
  background-color: white;
  border-radius: 20px;
  margin-left: 700px;
  margin-right: 400px;
  margin-top: 80px;
  height: 30vh;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

.fieldInput {
  width: 32%;
  min-width: 120px;
  text-align: left;
  float: right;
}

/* Subscription Page and Admin - Info Box */
.SelectedStationsInfoBox {
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 22px;
    padding-left: 30px;
    margin-bottom: 15px;
    margin-top: 10px;
  
    max-width: 750px;
    box-sizing:border-box;
    text-align: left;
}
    .SelectedStationsInfoBox h3 {
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 4px;
    }
    .SelectedStationsInfoBox p {
        margin-bottom: 0px;
    }

.SubscriptionsInfoBox {
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 22px;
    padding-left: 30px;
    margin-bottom: 15px;
  
    text-align: left;

    vertical-align: middle;
    display: table;

    width: 85%;
    max-width: 750px;
    min-width: 300px;
}
    .SubscriptionsInfoBox p {
        margin-top: 0px; 
        vertical-align: middle; 
        display: table-cell;
        padding: 0px 0px 0px 24px;
    }
    .SubscriptionsInfoBox img {
        width: 50px;
        height: 50px;
        display: table-cell;
    }

/* Subscribe Page - Subscribe Box */
.SubBox {
    margin-top: 10px;
    height: 50px;
    padding-bottom: 8px;
}
    .SubBox small {
        padding-bottom: 4px;

        font-size: 12px;
        text-align: right;
    }

.CancelDeviceButton {
    background-color: #e83434; /* Green */
    border: none;
    width: 22px;
    height: 22px;
    padding: 4px;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
    border-radius: 100%;
    float: right;
}

.SubBottom {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
}
    .SubBottom p {
        margin-top: 2px;
    }

.SubscribePrice {
    margin-top: 10px;
    flex: 1 1;
}

.SubscribeStation {
    margin-top: 0px;
}

/* Subscribe Page - Top Section */
.SubscribeTop {
    display: flex;
    flex-wrap: wrap-reverse;
}

/* Sbuscription Item */
.SubscriptionItem, .ConfirmSubscriptionItem {
    padding: 7px;

    border: 1px solid black;
    background-color: white;

    margin-bottom: 9px;
}
    .SubscriptionItem p, .CurrentSubscriptionItem p, .ConfirmSubscriptionItem p {
        display: inline-block;
        position: relative;
        bottom: 5px;

        font-size: 14px;
        margin-top: 0px;
    }

.SubscriptionItem {
    display: flex;
    align-content: space-between;
    align-items: center;

    width: 328px;
    max-width: none;
    min-width: unset;

    margin-bottom: 0px;
}
    .SubscriptionItem p {
        bottom: 0px;
    }

.CurrentSubItem {
    display: inline-block;
    margin-top: 3px;
}
    .CurrentSubItem p {
        margin-bottom: 0px;
        display: block;
    }



/* Select Weather Station Component */

#SubscribeWeatherStation {
    width: 100%;
    margin-bottom: 10px;
    max-width: 750px;
    min-width: 350px;
    margin-right: 10px;
}

.SubscribeHeader {
    background-color: #2e9345;
    color: white;

    text-align: left;
    font-size: 28px;

    padding: 12px;
    border-radius: 0px;

}

/* Current Subscription */

#CurrentSubscription {
    min-width: 400px;

    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);

    text-align: left;
    padding: 12px;
    padding-left: 15px;
    padding-bottom: 35px;
    margin-bottom: 10px;
}
    #CurrentSubscription h3 {
        margin-top: 5px;
        margin-bottom: 25px;
    }


@media screen and (max-width: 1568px) {
    #CurrentSubscription {
    min-width: 300px;

    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);

    text-align: left;
    padding: 12px;
    padding-left: 15px;
    padding-bottom: 35px;
    }

    .CurrentSubscriptionsContainer{
        width: 100%;

        max-width: 750px;
        min-width: 350px;

        margin-right: 10px;
    }

    .CurrentSubscriptionItem {

        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
    }
}

.CurrentSubscriptionItem {
    width: 95%;
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;

    max-width: none;
    min-width:auto;
}

/* Confirmation */
.ConfirmSubscriptionItem p{
    margin-top: 5px;
}

h2 {
    margin-top: 0px;
}

/* Subscription Items */
.UserSubA {
    padding: 7px;

    border: 1px solid black;
    background-color: white;

    margin-bottom: 9px;
    display: flex;
    align-items: center;
    align-content: space-between;
    
}
    .UserSubA p {
        display: inline-block;

        font-size: 14px;
        margin-top: 0px;
    }

.SubLeft {
    display: flex;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    align-items: center;
}

.SubRight {
    display: inline-block;
    position: relative;
    text-align: right;
    margin-left: auto;
}
    .SubRight button{
        margin-left: 5px;
    }

    #approve, #extend, #renew{
        background-color: #84e38f;
    }

    #decline, #remove{
        background-color: #e67c7f;
    }

.circleE, .circleF, .circleG {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;
}
    .circleE {
        background-color: rgb(226, 171, 19);
    }
    .circleF {
        background-color: #2e9345;
    }
    .circleG {
        background-color: #c01d1d;
    }

.FieldShapeButton {
    width: 200px;
    margin-left: auto;
}

.PendingRequests {
    height: 26px;
    width: 280px;
    border-radius: 15px;
    border: 1px solid black;
    display: inline-block;

    margin-right: 5px;
    
    background-color: rgb(226, 171, 19);
    color: white;
}
    .PendingRequests p {
        margin-top: 3px;
    }

    #activeContainer{
        background-color: #367c2b;
    }

    #activeContainer:hover{
        background-color: #216431;
    }

    #expiredContainer{
        background-color: #c01d1d;
    }

    #expiredContainer:hover{
        background-color: #9e1717;
    }

    #pendingContainer:hover{
        background-color: rgb(180, 137, 16);
    }

.FilterAndSearch, .Pending {
    display: inline-block;
    width: 50%;
}
    .Pending {
        text-align: left;
    }
        .Pending p {
            text-align: center;
        }
    .FilterAndSearch {
        float: right;
        text-align: right;
        display: inline;
    }

.Requests {
    width: 900px;
}

.adminTable {
    width: 1021px;
}


/* User Field Box */
.FieldBox {
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    align-content: space-between;
}

#adminForm {
    padding-top: 60px;
}
