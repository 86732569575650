#main  {
  border-radius: 5px;
  margin: 0px;
}

.maintitle {
  margin: 0px;
  font-size: 50px;
  text-align: left;
}

.secondTitle {
  text-align: center;
  font-size: 40px;
}

.profileIcon {
  font-size: 250px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.group1 {
  flex: 1;
  height: 100vh;
  flex-basis: 50%;
  text-align: left;
  border-right: 5px solid lightgray;
}

.group2 {
  flex: 2;
  height: 75vh;
  flex-basis: 50%;
  border-top: 5px solid lightgray;
  border-bottom: 5px solid lightgray;
  margin-top: 10vh;
  padding-top: 50px;
  line-height: 30px;
}

.group3 {
  flex: 3;
  height: 75vh;
  flex-basis: 50%;
  border-top: 5px solid lightgray;
  border-bottom: 5px solid lightgray;
  margin-top: 10vh;
  padding-top: 50px;
  line-height: 30px;
}

#Profile .formGroup .fieldName {
 color: black;
 display: inline-block;
 width: 50%;
 font-size: 20px;
}

#ChangePassword .formGroup .fieldName {
  color: black;
  display: inline-block;
  width: 50%;
  font-size: 20px;
 }

@media screen and (max-width: 500px) {
  #ChangePassword .formGroup .fieldName {
    color: black;
    display: inline-block;
    width: 50%;
    font-size: 15px;
   }

   #Profile .formGroup .fieldName {
    color: black;
    display: inline-block;
    width: 50%;
    font-size: 15px;
   }

   #Profile .fieldAnswer {
    color: black;
    display: inline-block;
    width: 50%;
    font-size: 15px;
    text-align: right;
  }
}

/* Unused Code 
.profilePage{
  display: flex;
  border: 5px solid lightgray;
  background-color: white;
  border-radius: 20px;
  margin-left: 500px;
  margin-right: 200px;
  margin-top: 80px;
  height: 100vh;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  justify-content: center;
  flex-direction: row;
}
*/ 
/* Profile Desktop Version */

.ProfileBoxOne, .ProfileBoxTwo, .ProfileBoxThree {
  border: 0px solid black;
  border-radius: 5px;
  background-color: rgb(204, 210, 212);

  padding: 9px;
  margin-bottom: 15px;

  /* width: 750px; */
  max-width: 750px;
  min-width: 300px;
  text-align: left;
}
  .ProfileBoxOne h2, .ProfileBoxTwo h2, .ProfileBoxThree h2 {
    font-size: 25px;
    margin-top: 0px;
  }

.fieldAnswer {
  color: black;
  display: inline-block;
  width: 50%;
  font-size: 20px;
  text-align: right;
  overflow-wrap: break-word;
}

.fieldName, .fieldAnswer {
  padding-bottom: 7px;
}

.changeLinkA, .changeLinkB {
  text-decoration: none;
  font-size: 25px;
  color: blue;
}
  .changeLinkA:hover, .changeLinkB:hover {
    color: #1a83ff;
  }
  .changeLinkA {
    text-align: left;
  }
  .changeLinkB {
    text-align: right;
  }

