/*
.api-Current-data tr:hover {
  background-color: #4b9e5e;
}

.api-Historic-data tr:hover {
  background-color: #4b9e5e;
}
*/

table {
  /*box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
  border-radius: 5px;
  padding: 3px;
  margin-right: 13px;
  width: 330px;
  flex: 1;
  margin-bottom: 5px;
}

table th {
  background: #367c2b;
  color: white;
  padding: 3px;
}

table td {
  height: 60px;
}

.field-data tr:nth-child(odd) {
  background-color: #fff;
}

.field-data tr:nth-child(even) {
  background-color: #eee;
}

/*
.field-data tr:hover {
  background-color: #4b9e5e;
}
*/

.field-data  td{
  padding: 5px;
  width: 150px;
}

.field-data th {
  font-size: 25px;
}

.filterid td{
  background-color: white;
}

#APIBLOCK
{
    display:inline;
}


#DashboardContent {
  display: flex;
  width: 1500px;
}
  #DashboardLeft {
    flex-basis: 825px;
  }
  #DisplayRight {
    flex: 1;
    float: left;
  }

#bloc1 {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
  max-width: 803px;
  min-width: 300px;
}

#bloc1 div {
  flex: 1;
  flex-basis: auto;
}

.api-Current-data, .api-Historic-data {
  padding: 0px;
  display: grid;
  margin: 1px;
  list-style: none;
  background-color: #eee;
}
  .api-Current-data li, .api-Historic-data li {
    width: 100%;

    text-align: center;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .api-Current-data li:nth-child(odd), .api-Historic-data li:nth-child(odd) {
    background-color: #fff;
  }

#bloc1 h4 {
  background: #367c2b;
  color: white;
  padding: 3px;
  margin: 0px;
}

.api-Current-Box, .api-Current-Box {
  width: 100%;
  min-width: 300px;
  max-width: 800px;
}

.api-Current-Box {
  margin-right: 2px;
}

.api-Historic-Box {
  margin-left: 2px;
}

@media screen and (max-width: 900px) {
  #bloc1 {
    flex-wrap: wrap;  
  }
}

@media screen and (min-width: 900px) { 
  #bloc1 {
    flex-basis: initial;
  }

   .api-Current-data, .api-Historic-data {
      grid-template-columns: repeat(3, minmax(130px, 1fr));
  }

  .Current-Weather-ItemA {
    grid-area: span 1/span 3;
  }

  .Current-Weather-ItemB {
    grid-area: span 1/span 2;
  }

  .Yesterday-Weather-ItemA {
    grid-area: span 1/span 3;
  } 

  .api-Current-data li:nth-child(1), .api-Current-data li:nth-child(5), .api-Current-data li:nth-child(6),
  .api-Current-data li:nth-child(7), .api-Historic-data li:nth-child(1), .api-Historic-data li:nth-child(2),
  .api-Historic-data li:nth-child(3), .api-Historic-data li:nth-child(7), .api-Historic-data li:nth-child(8),
  .api-Historic-data li:nth-child(9) {
    background-color: #fff;
  }

  .api-Current-data li:nth-child(3), .api-Current-data li:nth-child(9), .api-Historic-data li:nth-child(5) {
    background-color: #eee;
  }
}
