@media screen and (max-width: 900px) {
    .NavbarPadding {
        margin-left: 0px;
        margin-top: 88px;
    }

    #SelectWeatherStation {
        margin-bottom: 10px;
        min-width: 300px;
        max-width: 900px;
        width: 100%;
    }
}