.note {
    background-color: #fef68a;
    border-radius: 7px;
    padding: 1rem;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: pre-wrap;
    margin: 10px;
    width: 285px;
    text-align: left;
  }
  
  textarea:not(#messageArea){
    border: none;
    resize: none;
    background-color: #fef68a;
  }

  #messageArea{
    width: 40%;
    max-width: 300px;
    min-width: 200px;
    max-height: 100px;
    min-height: 50px;
    resize: none;
    float: right;
  }
  
  textarea:focus {
    outline: none;
  }
  
  .notes-list {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 250px);
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    border-radius: 5px;
    grid-row-start: 1;
    grid-row-end: 3;
    width: 330px;
    word-wrap: break-word;
  }
  
  .save {
    background-color: #e1e1e1;
    color: black;
    border: none;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    margin-left: 5px;
  }

  .save:hover {
      background-color: gray;
      cursor: pointer;
  }

  .note-delete-btn {
    background-color: #e1e1e1;
    color: black;
    border: none;
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    margin-left: 5px;
  }

  .note-delete-btn:hover {
    background-color: gray;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 5px;
    text-align: left;
  }

  .save {
    margin-left: 220px;
    width: 100px;
  }

  .NotesHeader {
    padding-left: 10px;
  }