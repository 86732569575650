#mains {
    border: 3px;
    /*box-shadow: 3px 5px grey;*/
    border-radius: 5px;
    text-align: center;
    background-color: rgb(204, 210, 212);

    padding: 9px;
    padding-bottom: 4px;
  
    max-width: 750px;
    min-width: 300px;
    height: 200px;
    font-size: 25px;
    text-align: left;
}
    #mains input {
        margin-top: 7px;
        height: 23px;
        width: 40%;
        max-width: 300px;
        min-width: 200px;
        float: right;
    }
    
#addField {
    border-radius: 5px;
    font-family: 'Roboto Condensed', sans-serif;
    margin-bottom: 20px;
    padding-left: 5px;

}
    #addField h2 {
        margin-top: 5px;
        margin-bottom: 7px;
    }
    #addField p {
        padding: 2px;
        margin: 0px;

        font-size: 20px;
        color: rgb(224, 69, 69);
    }


#addField .formGroup {
    padding: 5px;
    
    
}

#addField .formGroup .fieldName  {
   padding-right: 5px;
   display: inline-block;
   text-align: left;
   vertical-align: top;
   max-width: 300px;
   min-width: 100px;

}


Form .addField{
    border: 3px solid black;
    background-color: #7CB9E8;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
}

img .addField{
    width: auto;
    height: auto;
    
} 

Button .addField{
    margin-top: 5px;
    color: #FFFFFF;
    background-color:  #69FF00;
    border-radius: 5px;
    height: 30px;
    transition: all 0.5s;
    cursor: pointer;
    /*box-shadow: 0 5px #999;*/
    border: none;
}

Button .registerButton .addField{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

Button .registerButton:after .addField{
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

Button:hover .addField{
    background-color: darkgreen;
}

Button:hover .registerButton .addField{
    padding-right: 25px;
}

Button:hover .registerButton:after .addField{
    opacity: 1;
    right: 0;
  }

Button:active .addField{
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
} 

 @media (min-width: 830px){
    #main{
        /*border: 3px solid black; */
        text-align: center;
    }

    #registerForm .formGroup .addField{
        display: inline-block;
        text-align: right;
        vertical-align: top;
    }

    #registerForm .formGroup .addField{
        display: inline-block;
        text-align: right;
        vertical-align: top;

    }

    Form .addField {
        margin-left: 550px;
        margin-right: 250px;
        margin-bottom: 50px;
    }

    img .addField{
        width: auto;
        height: auto;
    }
} 