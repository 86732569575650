/* NOTE TO DEVELOPERS: 
 * The navigation bar for the desktop acts as it's own spot. If you want to 
 * create a new page you're going to need to apply the .NavbarPadding class
 * to the beginning of the container you want the content to be.
 */

@import url('https://kit.fontawesome.com/a076d05399.js');

/* Desktop Version */

.Main-Dashboard {
    padding-left: 7px;
}

.NavbarPadding {
    margin-left: 342px;
    margin-top: 27px;
    padding: 0px 20px 0px 20px;
}

#toggle:checked ~ .Navbar {
    visibility: collapse;
}
    #toggle:checked ~ .NavbarPadding {
        visibility: collapse;
    }

.profile {
    color: white;
    text-align: center;
    font-size: 13px;
}
    .profile h2 {
        padding-bottom: 10px;
    }

/* Desktop - Navigation Bar */
.Navbar {
    justify-content: center;
    align-items: center;
    background-color: #2c292c;

    position: fixed;
    top: 0px;
    float: left;

    width: 315px;
    height: 100%;
    overflow-y: auto;
    z-index: 1;

}

.Navbar-list {
    padding-left: 7px;
    width: 300px;
    color: white;
}

.Navbar-list .list-item {
    padding-top: 7px;
    margin: 0;

    list-style-type: none;
    display: flex;
    flex-direction: row;

    /*justify-content: start;*/
    /*align-items: center;*/
    border-radius: 5px;
    height: 5vh;
}
    .Navbar-list .list-item:hover {
        cursor: pointer;
        background-color: #444244;
    }   
    .menu-links {
        color: white;
        text-decoration: none;
        font-family: 'Lato', sans-serif;
        padding-left: 10px;
    }

.Box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.list-item {
    height: 52px;
}

/* Desktop Icons */
.icon span{
    font-size: 2rem;
    color: white;
    margin-left: 5px;
    margin-right: 15px;
}

.logo {
    margin-top: 20px;
    padding: 9px;

    width: 250px; 
}

.navIconA {
    display: none;
}

@media screen and (min-width: 540px) {
    .Navbar-list .list-item {
        font-size: 20px;
    }

}

@media screen and (max-height: 1500px) {
    .Navbar-list .list-item {
        font-size: 20px;
    }
}

/* Mobile */
@media screen and (max-width: 900px) {
    .Navbar {
        width: 100%;
        height: 88px;
        overflow: auto;
        transition: max-height 0.5s;
        max-height: 88px;
        overflow-y: hidden;
    }

    .NavbarPadding {
        margin-left: 0px;
        margin-top: 88px;
    }

    .Navbar.open {
        height: 100%;
        max-height: 100%;
    }

    .logo {
        width: 150px;
        margin: 0px;
    }
    
    .Navbar-list {
        padding-left: 0px;
        width: inherit;
        margin: 0px;
        font-size: 20px;
    }
        .list-item {
            padding-left: 100px;
            font-size: 20px;
        }
    
    /* X and Hamburger menu class */
    .navIconA {
        display: inline;
        width: 25px;
    }

    .profile {
        background-color: #242224;
        position: relative;
    }
        .profile button {
            float: left;
            width: 35px;
            border: none;
            background-color: #242224;
            cursor: pointer;

            position: absolute;
            left: 39px;
            top: 50%;
            transform: translate(0, -50%);
        }

    .Box {
        justify-content: unset;
    }

    #Profile, #thanks {
        margin-left: 0px;
        margin-top: 88px;
    }

    #ChangePassword {
        margin-left: 0px;
        margin-top: 88px;
    }

    #Subscribe {
        margin-left: 0px;
        margin-top: 88px;
    }

    #addField {
        margin-left: 0px;
    }

    #confirm{
        margin-left: 0px;
    }

}