body
{
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.map {
    width: 55vw;
    height: 55vh;
    position: relative;
    left: 300px;
}