
/* Subscription Page and Admin - Info Box */
.SelectedStationsInfoBox {
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 22px;
    padding-left: 30px;
    margin-bottom: 15px;
    margin-top: 10px;
  
    max-width: 750px;
    box-sizing:border-box;
    text-align: left;
}
    .SelectedStationsInfoBox h3 {
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 4px;
    }
    .SelectedStationsInfoBox p {
        margin-bottom: 0px;
    }

.SubscriptionsInfoBox {
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 22px;
    padding-left: 30px;
    margin-bottom: 15px;
  
    text-align: left;

    vertical-align: middle;
    display: table;

    width: 85%;
    max-width: 750px;
    min-width: 300px;
}
    .SubscriptionsInfoBox p {
        margin-top: 0px; 
        vertical-align: middle; 
        display: table-cell;
        padding: 0px 0px 0px 24px;
    }
    .SubscriptionsInfoBox img {
        width: 50px;
        height: 50px;
        display: table-cell;
    }

/* Subscribe Page - Subscribe Box */
.SubBox {
    margin-top: 10px;
    height: 50px;
    padding-bottom: 8px;
}
    .SubBox small {
        padding-bottom: 4px;

        font-size: 12px;
        text-align: right;
    }

.CancelDeviceButton {
    background-color: #e83434; /* Green */
    border: none;
    width: 22px;
    height: 22px;
    padding: 4px;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
    border-radius: 100%;
    float: right;
}

.SubBottom {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
    .SubBottom p {
        margin-top: 2px;
    }

.SubscribePrice {
    margin-top: 10px;
    flex: 1;
}

.SubscribeStation {
    margin-top: 0px;
}

/* Subscribe Page - Top Section */
.SubscribeTop {
    display: flex;
    flex-wrap: wrap-reverse;
}

/* Sbuscription Item */
.SubscriptionItem, .ConfirmSubscriptionItem {
    padding: 7px;

    border: 1px solid black;
    background-color: white;

    margin-bottom: 9px;
}
    .SubscriptionItem p, .CurrentSubscriptionItem p, .ConfirmSubscriptionItem p {
        display: inline-block;
        position: relative;
        bottom: 5px;

        font-size: 14px;
        margin-top: 0px;
    }

.SubscriptionItem {
    display: flex;
    align-content: space-between;
    align-items: center;

    width: 328px;
    max-width: none;
    min-width: unset;

    margin-bottom: 0px;
}
    .SubscriptionItem p {
        bottom: 0px;
    }

.CurrentSubItem {
    display: inline-block;
    margin-top: 3px;
}
    .CurrentSubItem p {
        margin-bottom: 0px;
        display: block;
    }



/* Select Weather Station Component */

#SubscribeWeatherStation {
    width: 100%;
    margin-bottom: 10px;
    max-width: 750px;
    min-width: 350px;
    margin-right: 10px;
}

.SubscribeHeader {
    background-color: #2e9345;
    color: white;

    text-align: left;
    font-size: 28px;

    padding: 12px;
    border-radius: 0px;

}

/* Current Subscription */

#CurrentSubscription {
    min-width: 400px;

    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);

    text-align: left;
    padding: 12px;
    padding-left: 15px;
    padding-bottom: 35px;
    margin-bottom: 10px;
}
    #CurrentSubscription h3 {
        margin-top: 5px;
        margin-bottom: 25px;
    }


@media screen and (max-width: 1568px) {
    #CurrentSubscription {
    min-width: 300px;

    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);

    text-align: left;
    padding: 12px;
    padding-left: 15px;
    padding-bottom: 35px;
    }

    .CurrentSubscriptionsContainer{
        width: 100%;

        max-width: 750px;
        min-width: 350px;

        margin-right: 10px;
    }

    .CurrentSubscriptionItem {

        display: flex;
        align-items: center;
        column-gap: 10px;
    }
}

.CurrentSubscriptionItem {
    width: 95%;
    display: flex;
    align-items: center;
    column-gap: 10px;

    max-width: none;
    min-width:auto;
}

/* Confirmation */
.ConfirmSubscriptionItem p{
    margin-top: 5px;
}
