

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    top: 0%;
    left: 103%;
    position: fixed;
  }
  .modal-content {
    background-color: #2F4050;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }