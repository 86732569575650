.Historic-List, .Current-Station-List, .Yesterday-Station-List {
    padding: 0px;
    display: grid;
    margin: 1px;
    list-style: none;
}
    .Historic-List li, .Current-Station-List li, .Yesterday-Station-List li {
        width: 100%;
        text-align: center;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

.Click {
    margin-left: 0px;
}

/* Weather Stations */
.CurrentStation {
    width: 100%;
    min-width: 330px;
    max-width: 802px;
    background-color: #eee;
}

.WeatherStation {
    margin-bottom: 10px;
}

.calWeatherStation {
    margin-left: 0px;
    padding: 11px;
}

.Historic-Div {
    width: 100%;
    max-width: 803px;
    min-width: 300px;
}
    .Historic-Div p {
        padding-bottom: 10px;
    }
    .Historic-Div ul {
        background-color: #eee;
    }

.Historic-Header {
    background-color: white;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Current-Station-Header, .Yesterday-Station-Header {
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* Calendar Historic Weather Stations */

.Historic-List, .Current-Station-List, .Yesterday-Station-List {
    grid-template-columns: repeat(2, minmax(130px, 1fr));
    align-items: center;
    grid-auto-rows: 65px;
}
    
    .Historic-List li:nth-child(3), .Historic-List li:nth-child(4),
    .Historic-List li:nth-child(7), .Historic-List li:nth-child(8),
    .Historic-List li:nth-child(11), .Historic-List li:nth-child(12),
    .Historic-List li:nth-child(15), .Historic-List li:nth-child(16),
    .Current-Station-List li:nth-child(3), .Current-Station-List li:nth-child(4), 
    .Current-Station-List li:nth-child(7), .Current-Station-List li:nth-child(8),
    .Current-Station-List li:nth-child(11), .Current-Station-List li:nth-child(12),
    .Yesterday-Station-List li:nth-child(3), .Yesterday-Station-List li:nth-child(4), 
    .Yesterday-Station-List li:nth-child(7), .Yesterday-Station-List li:nth-child(8),
    .Yesterday-Station-List li:nth-child(11), .Yesterday-Station-List li:nth-child(12), 
    .Yesterday-Station-List li:nth-child(15), .Yesterday-Station-List li:nth-child(16) 
    {
        background-color: #fff;
    }

.Current-Station-Item-A {
    grid-area: span 1/span 2;
}

@media screen and (min-width: 900px) {
    .Historic-List {
        grid-template-columns: repeat(4, minmax(130px, 1fr));
    }
        .Historic-List li {
            padding: 10px 0px;
        }
        .Historic-List li:nth-child(5), .Historic-List li:nth-child(6),
        .Historic-List li:nth-child(7), .Historic-List li:nth-child(8),
        .Historic-List li:nth-child(13), .Historic-List li:nth-child(14),
        .Historic-List li:nth-child(15), .Historic-List li:nth-child(16)
        {
            background-color: #fff;
        }

        .Current-Station-List li:nth-child(3), .Current-Station-List li:nth-child(4),
        .Current-Station-List li:nth-child(11), .Current-Station-List li:nth-child(12),
        .Yesterday-Station-List li:nth-child(3), .Yesterday-Station-List li:nth-child(4), 
        .Yesterday-Station-List li:nth-child(11), .Yesterday-Station-List li:nth-child(12)
        {
            background-color: #eee;
        }

        .Historic-List li:nth-child(3), .Historic-List li:nth-child(4),
        .Historic-List li:nth-child(11), .Historic-List li:nth-child(12) {
            background-color: initial;
        }

    /* Current and Yesterday Weather Stations */
    .Current-Station-Header, .Yesterday-Station-Header {
        background-color: #fff;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .Current-Station-Header {
        display: flex;
    }
        .Header-Align-Right {
            text-align: right;
            flex: auto;
        }

    .Current-Station-List, .Yesterday-Station-List{
        grid-template-columns: repeat(4, minmax(130px, 1fr));
        align-items: center;
        grid-auto-rows: 60px;
    }
        .Current-Station-List li:nth-child(5), .Current-Station-List li:nth-child(6), 
        .Current-Station-List li:nth-child(7), .Current-Station-List li:nth-child(8),
        .Current-Station-List li:nth-child(13), 
        .Yesterday-Station-List li:nth-child(5), .Yesterday-Station-List li:nth-child(6), 
        .Yesterday-Station-List li:nth-child(7), .Yesterday-Station-List li:nth-child(8),
        .Yesterday-Station-List li:nth-child(13), .Yesterday-Station-List li:nth-child(14), 
        .Yesterday-Station-List li:nth-child(15), .Yesterday-Station-List li:nth-child(16) {
            background-color: #fff;
        }

    .Current-Station-Item-A {
        grid-area: span 1/span 4;
    }

    .Current-Station-Title {
        position: absolute;
        left: 737px;
    }
}


@media screen and (max-width: 900px) {
    .WeatherStation {
        width: auto;
    }

    .WeatherStation table{
        word-wrap: break-word;
        table-layout: unset;

        width: 100%;
    }

    .Click {
        width: auto;
        min-width: 300px;
        max-width: 778px;

        font-size: 18px;
    }
}