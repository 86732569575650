.Main-title {
    margin: 0px;
    font-size: 48px;
    text-align: left;
    padding: 0px 0px 12px 0px;
}

.env {
    text-align: right;
    margin: 0px;
    padding: 0px;
    color: black;
    font-weight: bold;
}

span {
    color: black;
    font-weight: bold;
}

.links {
    font-size: 25px;
    margin-top: 50px;
    text-align: left;
}

.links .link {
    text-decoration: none;
    color: black;
    margin-left: 15px;
    /* color: rgb(71, 197, 71); */
}

.links .link:hover {
    color: #1a83ff;
}

.clock {
    font-size: 25px;
    color: black;
    float: left;
    text-align: left;
    margin-top: -15px;
    margin-left: 5px;
}

.idfilter {
    background-color: #367c2b;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    height: 30px;
    box-shadow: 0 5px #999;
    border: none;
}

.idfilter:hover {
    background-color: #2c6423;
}

.FieldContainer{
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 9px;
    padding-bottom: 40px;
  
    width: 450px;
    font-size: 25px;
    text-align: left;
}
    .FieldContainer label{
        line-height: 1.6;
    }
    .FieldContainer input{
        margin-top: 7px;
        height: 23px;
        float: right;
    }

.Center {
    margin-top: 4px;
    float: inline-start;
}

/* Subscription Page - Thank You Page */
.ThankYouText {
    font-size: 20px;
    text-align: left;
    padding-bottom: 25px;
}

#contactButton{
    margin-right: 10px;
}

/* Subscribe Buttons */
.SubscribeButtonBox {
    margin-top: 15px;
    padding-bottom: 40px;
}

.SubscribeButtonsA {
    float: left;
    padding: 7px 15px 7px 15px;
}
.SubscribeButtonsB {
    float: right;
    padding: 7px 45px 7px 45px;
}
.SubscribeButtonsC {
    float: left;
    padding: 7px 25px 7px 25px;
}

.SubscribeButtonsD {
    float: right;
    padding: 7px 105px 7px 105px;
}

/* Subscription Page and Admin - Info Box */
.GeneralInfoBox {
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 22px;
    padding-left: 30px;
    margin-bottom: 15px;
    width: 85%;
    max-width: 750px;
    min-width: 300px;
    text-align: left;
}
    .GeneralInfoBox h3 {
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 4px;
    }
    .GeneralInfoBox p {
        margin-bottom: 0px;
    }

.SubscriptionsInfoBox {
    border: 0px solid black;
    border-radius: 5px;
    background-color: rgb(204, 210, 212);
  
    padding: 22px;
    padding-left: 30px;
    margin-bottom: 15px;
  
    width: 85%;
    max-width: 750px;
    min-width: 300px;
    text-align: left;

    vertical-align: middle;
    display: table;
}
    .SubscriptionsInfoBox p {
        margin-top: 0px; 
        vertical-align: middle; 
        display: table-cell;
        padding: 0px 0px 0px 24px;
    }
    .SubscriptionsInfoBox img {
        width: 50px;
        height: 50px;
        display: table-cell;
    }

.UserInfoBox {
    display: flex;
}
    .UserInfoBoxA h4 {
        margin-bottom: 0px;
    }
    .UserInfoBoxA p {
        margin-top: 3px;
        margin-bottom: 35px;
    }

.UserInfoBoxA {
    flex: 1;
}

/* Subscribe Page - Subscribe Box */
.SubBox {
    height: 50px;
    padding-bottom: 8px;
}
    .SubBox small {
        padding-bottom: 4px;

        font-size: 12px;
        text-align: right;
    }

.CancelDeviceButton {
    background-color: #e83434; /* Green */
    border: none;
    width: 22px;
    height: 22px;
    padding: 4px;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
    border-radius: 100%;
    float: right;
}

.SubBottom {
    display: flex;
}
    .SubBottom p {
        margin-top: 2px;
    }

.SubscribePrice {
    min-width: 250px;
    margin-top: 10px;
    flex: 1;
}

.SubscribeStation {
    margin-top: 0px;
}

/* Subscribe Page - Top Section */
.SubscribeTop {
    display: flex;
}

/* Sbuscription Item */
.SubscriptionItem, .CurrentSubscriptionItem, .ConfirmSubscriptionItem {
    width: 60%;
    max-width: 480px;
    min-width: 240px;
    padding: 7px;

    border: 1px solid black;
    background-color: white;

    margin-bottom: 9px;
}
    .SubscriptionItem p, .CurrentSubscriptionItem p, .ConfirmSubscriptionItem p {
        display: inline-block;
        position: relative;
        bottom: 5px;

        font-size: 14px;
        margin-top: 0px;
    }

.SubscriptionItem {
    display: flex;
    align-content: space-between;
    align-items: center;
}
    .SubscriptionItem p {
        bottom: 0px;
    }

.CurrentSubItem {
    display: inline-block;
    margin-top: 3px;
}
    .CurrentSubItem p {
        margin-bottom: 0px;
        display: block;
    }

.circleA {
    height: 20px;
    width: 20px;
    background-color: rgb(28, 132, 230);
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;

    margin-right: 5px;
}

.circleB, .circleC, .circleD {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid black;
    display: inline-block;
    color: white;

}
    .circleB {
        background-color: rgb(226, 171, 19);
    }
    .circleC {
        background-color: #367c2b;
    }
    .circleD {
        background-color: #c01d1d;
    }


/* Select Weather Station Component */

#SelectWeatherStation {
    width: 802pxpx;
    margin-bottom: 10px;
}

.CaretIcon{
    width: 25px;
    height: 25px;
    padding: 4px;
    margin: 0;
    cursor: pointer;
    float: right;
}

.Click {
    width: 778px;

    background-color: #367c2b;
    color: white;

    text-align: left;
    font-size: 28px;

    padding: 12px;
    margin-left: 2px;
    border-radius: 0px;

}

/* Current Subscription */


.CurrentSubscriptionItem {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

/* Confirmation */
.ConfirmSubscriptionItem p{
    margin-top: 5px;
}


/* Mobile */
@media screen and (max-width: 900px) {
    .Main-title {
        font-size: 47px;
    }
}